<template>
  <div :class="type">
    <shared-user :type="type" :titles="titles" :staff-type="staffType" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SharedUser from '@/components/user/index'
import { getUserRoles } from '@/utils/filters'

export default {
  name: 'Staffs',
  components: {
    SharedUser
  },
  data() {
    return {
      type: 'staff',
      userRoles: getUserRoles()
    }
  },
  computed: {
    ...mapGetters(['accountOrg']),
    staffType() {
      return this.accountOrg ? 'moderator' : 'administrator'
    },
    titles() {
      if (this.accountOrg) {
        return this.userRoles.filter((r) => r.value === 'moderator')
      }
      return this.userRoles.filter((r) => r.value !== 'user')
    }
  }
}
</script>

<style lang="scss" scoped></style>
